import React, { useEffect, useState } from 'react'
import Styles from "../styles/components/DegreeBar.module.scss"
import clsx from "clsx"

const tenArray = Array(10).keys()
const oneToTenArray = [ ...tenArray ].map( i => ++i )

export const DegreeBar = ( {
    value
}: any ) => {

    const [ number_, setNumber ] = useState( 0 )

    useEffect( () => {
        for( let i = 1; i <= value; i++ ) {
            setTimeout( () => setNumber( i ), i * 100 )
        }
    }, [] )

    return (
        <p>
            <div className={ Styles.container }>
                { oneToTenArray.map( number =>
                    < div className={ clsx( Styles.box, number <= number_ && Styles.colored ) } key={ number }>
                        { number === 1 && <div className={ clsx( Styles.number, Styles.zero ) }>0</div> }
                        <div className={ Styles.number }>{ number }</div>
                    </div>
                ) }
            </div >
        </p>
    )
}
