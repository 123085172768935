import { contentType } from "../components/TableOfContents";

const createContentOfTable = ( header: any ) => header.tagName === "h2" ?
    {
        tagName: header.tagName,
        text: header.children[ 0 ].value,
        children: []
    } :
    {
        tagName: header.tagName,
        text: header.children[ 0 ].value,
    };

const insertContentToTree = ( tree: any, content: any ) => {
    const lastOne = tree.slice( -1 )[ 0 ];
    if ( lastOne && lastOne.tagName === "h2" ) {
        return [ ...tree.slice( 0, -1 ), { ...lastOne, children: [ ...lastOne.children, content ] } ];
    }
    else {
        return [ ...tree, content ];
    }
};

export const getTableOfContent = ( content: React.ReactNode):contentType[] => {
    if ( !content || !( content as any ).children )
        return []; // for cms preview
    const elementList = ( content as any ).children;
    const headerTagList = [ "h2", "h3" ];
    const headerElementList = elementList.filter( ( element: any ) => headerTagList.includes( element.tagName ) );
    let tableOfContent: any = [];
    headerElementList.forEach( ( headerElement: any ) => {
        const header = createContentOfTable( headerElement );
        if ( header.tagName === "h3" ) {
            tableOfContent = insertContentToTree( tableOfContent, header );
        }
        else {
            tableOfContent.push( header );
        }
    } );
    return tableOfContent
}
