import React, { FC, useState, useEffect } from 'react'

type Props = {
}

export const LeftColumn:FC<Props> = ({ }) => {

    return(
        <div>
            
        </div>
    )
}
