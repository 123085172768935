import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

export const CustomImage = ( props: any ) => {

    const filename = props.filename
    const width = props.width
    const height = props.height
    const objectFit = props.objectfit ? props.objectfit : "contain" //小文字

    return (
        <StaticQuery
            // GraphQLのクエリ引数には何も指定しない！
            query={ graphql`
            query {
                images: allFile {
                edges {
                    node {
                    relativePath
                    name
                    childImageSharp {
                        sizes(maxWidth: 800) {
                        ...GatsbyImageSharpSizes
                        }
                        fluid(maxWidth: 1000) {
                        ...GatsbyImageSharpFluid
                        }
                        fixed(width: 800) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                    }
                }
                }
            }
            `}

            // 全画像情報がdataに代入されている
            render={ ( data ) => {
                const image = data.images.edges.find( ( n: any ) => filename.includes( n.node.relativePath ) )
                !image && console.log( "image", filename )
                if ( !image ) return
                const imageSizes = image.node.childImageSharp.sizes
                image.node.childImageSharp.fixed.width = width
                image.node.childImageSharp.fixed.height = height
                //   console.log("Size", data.file.childImageSharp.fixed)
                // fluid={props.data.imageBanner.childImageSharp.fluid}
                //   return <Img　fluid={image.node.childImageSharp.fluid}/>

                // return <Img　fixed={image.node.childImageSharp.fixed} imgStyle={{objectFit}}/>

                // imageSizes.sizes = "(max-width: 180px) 50vw, 180px"
                // const imageSizes = image.node.childImageSharp.sizes
                return <Img sizes={ imageSizes } filename={ filename } style={ { width, height } } />

            } }
        />
    );
}
