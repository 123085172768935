import React from 'react'
import Styles from "../styles/components/Bubble.module.scss"
import clsx from "clsx"

export const Bubble = ( {
    position,
    name,
    children
}: any ) => (
    <div className={ clsx( Styles.container, position === "right" ? Styles.right : Styles.left ) }>
        <div className={ Styles.bubble }>
            { children.map( (element: any) => typeof element === "string" ?
                // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
                element.match( /(?:\W)([\s\S]*$)/ ).slice( 1 ) :
                element ) }
        </div>
        <span className={ Styles.name }>{ name }</span>
    </div>
)

