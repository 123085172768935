import React, { FC, useEffect } from 'react';
import Styles from "../styles/components/PostLayout.module.scss";
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import { Layout } from '../components/Layout';
import Content, { HTMLContent } from '../components/Content';
import { Breadcrumbs } from "../components/Breadcrumbs";
import { HeroImage } from "../components/HeroImage";
import { TagList } from "../components/TagList";
import { TableOfContents } from "../components/TableOfContents";
import { useTableOfContent } from '../hooks/useTableOfContent';
import { getTableOfContent } from '../utils/getTableOfContent';
import { LeftColumn } from '../components/LeftColumn';
import { RightColumn } from '../components/RightColumn';
import { useSetIdToHeadings } from '../hooks/useSetIdToHeadings';

type PostTemplateProps = {
    content: React.ReactNode;
    contentComponent?: ( ...args: any[] ) => any;
    description?: string;
    title?: string;
    helmet?: any;
    path: string
    tags: any[]
    featuredimage: any
};
// @ts-expect-error ts-migrate(2339) FIXME: Property 'tags' does not exist on type 'PostTempla... Remove this comment to see the full error message
export const PostTemplate: FC<PostTemplateProps> = ( { content, contentComponent, description, tags, title, helmet, slug, featuredimage, path, } ) => {

    const { setContent: setTableOfContent, tableOfContent, resetContent } = useTableOfContent()
    // useSetIdToHeadings( { tableOfContent } )

    const PostContent = contentComponent || Content;


    useEffect( () => {
        const tableOfContent__ = getTableOfContent( content )
        setTableOfContent( { content: tableOfContent__ } )
        return () => { resetContent() }
    }, [] )

    return (
        <div className={ Styles.container }>
            {featuredimage &&
                <HeroImage title={ title } image={ featuredimage.childImageSharp } height={ '50vh' } /> }
            <div className={ Styles.column_container }>
                <div className={ Styles.left_column }>
                    <LeftColumn />
                </div>
                <section className={ Styles.main_container }>
                    { helmet || '' }
                    <div>
                        <div>
                            <div>
                                <Breadcrumbs path={ path } />
                                { tags &&
                                    <TagList tags={ tags } /> }
                                <h1>
                                    { title }
                                </h1>
                                <p className={ Styles.description }>{ description }</p>
                                <div className={ Styles.table_of_contnets }>
                                    <TableOfContents />
                                </div>
                                <div className={ Styles.content }>
                                    <PostContent content={ content } />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className={ Styles.right_column }>
                    <RightColumn />
                </div>
            </div>
        </div> );
};


type PostProps = {
    data?: {
        markdownRemark?: any;
    };
};
const Post = ( { data }: PostProps ) => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'markdownRemark' does not exist on type '... Remove this comment to see the full error message
    const { markdownRemark: post } = data;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'site' does not exist on type '{ markdown... Remove this comment to see the full error message
    const { site: { siteMetadata: { host } } } = data;
    return (
        <Layout>
            <PostTemplate
                // content={post.html}
                content={ post.htmlAst }
                contentComponent={ HTMLContent }
                description={ post.frontmatter.description }
                helmet={
                    <Helmet titleTemplate="%s | Page">
                        <title>{ `${ post.frontmatter.title }` }</title>
                        <meta name="description" content={ `${ post.frontmatter.description }` } />
                        { post.frontmatter.featuredimage &&
                            <meta property="og:image"
                                // content={ post.frontmatter.featuredimage.absolutePath }
                                // content={ `${ host }${ withPrefix( '/' ) }${ post.frontmatter.featuredimage.publicURL }` }
                                content={ `${ host }${ post.frontmatter.featuredimage.publicURL }` } /> }
                    </Helmet> }
                path={ post.frontmatter.path }
                tags={ post.frontmatter.tags }
                title={ post.frontmatter.title }
                featuredimage={ post.frontmatter.featuredimage } />
        </Layout> );
};
export default Post;
export const pageQuery = graphql`
  query PostByID($id: String!) {
    site{
        siteMetadata {
            host
        }
    }
    markdownRemark(id: { eq: $id }) {
      id
      htmlAst
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        path
        tags
        featuredimage {
            publicURL
            absolutePath
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }        
      }
    }
  }
`;
