import React from "react"
import { FaChevronRight } from "react-icons/fa";
import { CSSProperties, FC } from "react";

type Props = {
    style?: CSSProperties,
    className?: string
    onClick?: () => void
}

export const RightArrowIcon: FC<Props> = ({className, style, onClick})=>
    <FaChevronRight className={ className} style={style} onClick={ onClick}/>
