import React from 'react';
import styled from '@emotion/styled';
import Styles from "../styles/atoms/Tag.module.scss";
import { Link, graphql, useStaticQuery } from 'gatsby';
const List = styled.ul`
  width: 100%;
  margin: 0 auto 1em auto;
  /* max-width: ${ props => ( props.theme as any ).sizes.maxWidthCentered }; */
`;
export const TagList = ( { tags }: any ) => {
    const allTagLink = useStaticQuery( graphql`
            query allTagList{
                allMarkdownRemark(
                    filter: { fields: { slug: {regex: "/tags.+/"} } }
                )  {
                    edges{
                        node {
                            frontmatter {
                                name
                                EnglishName
                            }
                        }
                    }
                }
            }
        `).allMarkdownRemark.edges.map( ( tag: any ) => tag.node.frontmatter );
    return (
        <List>
            {tags.filter( (tag:any) => allTagLink.find( ( eachTag: any ) => eachTag.name === tag )).map( ( tag: any ) =>
                <li
                    className={ Styles.tag }
                    key={ tag }>
                    <Link
                        to={
                            `/tag/${ allTagLink.find( ( eachTag: any ) => eachTag.name === tag ) ?
                                allTagLink.find( ( eachTag: any ) => eachTag.name === tag ).EnglishName :
                                tag }/` }>
                        { tag }
                    </Link>
                </li> ) }
        </List> );
};
