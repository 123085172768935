import React, { FC } from 'react'
import Styles from "../styles/components/TableOfContents.module.scss"
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'smoo... Remove this comment to see the full error message
import smoothscroll from 'smoothscroll-polyfill';
import { useTableOfContent } from '../hooks/useTableOfContent';
import clsx from 'clsx';

if ( ( typeof window !== 'undefined' ) && ( typeof document !== 'undefined' ) ) {
    smoothscroll.polyfill();
}

export type contentType = {
    tagName: string
    text: string
    children: contentType[]
}

export const TableOfContents: FC = () => {

    const { tableOfContent, currentHeading2Index } = useTableOfContent()

    const scrollToHeading = ( headingObject: any ) => {
        const headingList = Array.from( document.getElementsByTagName( headingObject.tagName ) )
        const heading = headingList.find( heading => heading.innerHTML === headingObject.text )
        const featureY = heading.getBoundingClientRect().top
        const offsetTop = window.pageYOffset
        const buffer = 48
        const top = featureY + offsetTop - buffer
        window.scrollTo( {
            top,
            behavior: "smooth"
        } );
    }

    if ( !tableOfContent.length ) return null

    return (
        <div className={ Styles.container }>
            <h4>目次</h4>
            <ol className={ Styles.heading2_list }>
                { tableOfContent.map( ( heading2, index ) =>
                    <li
                        className={ clsx( Styles.heading2_list_item, currentHeading2Index === index && Styles.current_heading2_item ) }
                        onClick={ () => scrollToHeading( heading2 ) }
                        key={ heading2.text }>
                        <div className={ Styles.heading2 }>{ heading2.text }</div>
                        { !!heading2.children.length && <ol className={ Styles.heading3_list }>
                            { heading2.children.map( heading3 =>
                                <li
                                    className={ Styles.heading3_list_item }
                                    onClick={ ( e ) => {
                                        e.stopPropagation();
                                        scrollToHeading( heading3 )
                                    } }
                                    key={ heading3.text }>
                                    { heading3.text }
                                </li>
                            ) }
                        </ol> }
                    </li> ) }
            </ol>
        </div>
    );
}

