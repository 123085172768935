import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import Styles from "../styles/components/Breadcrumbs.module.scss"
import { RightArrowIcon } from '../icons/RightArrowIcon';


export const Breadcrumbs = ( {
    path
}: any ) => {

    const allMarkdownRemarkPathAndTitleList = useStaticQuery(
        graphql`
            query allMarkdownRemarkPathAndTitle{
                allMarkdownRemark {
                    edges{
                        node {
                            # title_from_path
                            frontmatter {
                                title
                                path
                            }
                        }
                    }
                }
            }
    `).allMarkdownRemark.edges.map( ( post: any ) => post.node )
    if ( !path ) return null
    const linkPathList: string[] = path.split( "/" ).map( ( _, index: number, array: string[] ) =>
        array.slice( 0, index ).join( "/" )
    )

    const linkPathAndTitleList = linkPathList.filter( link => !!link ).map( link => link === "" ?
        {
            path: "/",
            title: "トップ"
        } : {
            path: "/" + link,
            title: allMarkdownRemarkPathAndTitleList.find( ( post: any ) => post.frontmatter.path === link ) ?
                allMarkdownRemarkPathAndTitleList.find( ( post: any ) => post.frontmatter.path === link ).frontmatter.title :
                "エラー"
        }
    )


    return (
        <div style={ { margin: "0 auto 8px" } }>
            <div className={ Styles.breadcrumbs_container }>
                <Link to="/">
                    トップ
                </Link>
                <span className={ Styles.icon_wrapper }>
                    <RightArrowIcon className={ Styles.icon } />
                </span>
                { linkPathAndTitleList.map( ( link: any, index: any ) =>
                    <span key={ link.title }>
                        <Link to={ link.path }>
                            { link.title }
                        </Link>
                        <span className={ Styles.icon_wrapper }>
                            <RightArrowIcon className={ Styles.icon } />
                        </span>
                    </span> )
                }
            </div>
        </div >
    );
}

