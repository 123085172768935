import React, { FC } from 'react';
import rehypeReact from "rehype-react";
import { CustomImage } from "./CustomImage";
import { DegreeBar } from './DegreeBar';
import { Bubble } from "./Bubble";

type H2Props = {
    children: React.ReactNode
}
const H2: FC<H2Props> = ( { children } ) => {
    const text = String( children )
    return (
        <>
            <div id={ text } style={ { transform: "translateY(-20px)" } }></div>
            <h2>{ children }</h2>
        </>
    )
}

type H3Props = {
    children: React.ReactNode
}
const H3: FC<H3Props> = ( { children } ) => {
    const text = String( children )
    return (
        <>
            <div id={ text } style={ { transform: "translateY(-20px)" } }></div>
            <h3>{ children }</h3>
        </>
    )
}

const renderAst = new rehypeReact( {
    createElement: React.createElement,
    components: {
        "custom-image": CustomImage,
        "degree-bar": DegreeBar,
        "bubble": Bubble,
        "h2": H2,
        "h3": H3,
    }
} ).Compiler;
// 
export const HTMLContent = ( { content, className }: any ) => {
    return ( <div className={ className }>{ renderAst( content ) }</div>
        // {renderAst(content)}
        // <div className={className} dangerouslySetInnerHTML={{ __html: content }} />
    );
};
type ContentProps = {
    content?: React.ReactNode;
    className?: string;
};
const Content = ( { content, className }: ContentProps ) => <div className={ className }>{ content }</div>;
HTMLContent.propTypes = ( Content as any ).propTypes;
export default Content;
