import React from 'react'
import Img from 'gatsby-image'
import Styles from "../styles/components/HeroImage.module.scss"

export const HeroImage = (props: any) => <div className={ Styles.container }>
    <Img
        className={ Styles.image }
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        height={ props.height }
        fluid={ props.image.fluid }
        backgroundColor={ '#eeeeee' }
        objectFit={ "contain" } />
</div>

