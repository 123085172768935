import React, { FC, useState, useEffect } from 'react'
import { useTableOfContent } from '../hooks/useTableOfContent'
import { HeaderTableOfContent } from './HeaderTableOfContent'
import { TableOfContents } from './TableOfContents'
import Styles from "../styles/components/RightColumn.module.scss"
import { useIsBigScreen } from '../hooks/useIsBigScreen'

type Props = {
}

export const RightColumn: FC<Props> = ( { } ) => {

    const isBigScreen = useIsBigScreen()

    return (
        <div className={ Styles.container }>
            <div className={ Styles.content }>
                { isBigScreen &&
                    <TableOfContents /> }
            </div>
        </div>
    )
}
